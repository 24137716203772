#projects {
    /* background-color: rgba(40,42,45,1); */
    color: white;
    margin-top: 20px;
}

.projects-wrapper {
    width: 90%;
    margin: 57.5px auto 57.5px auto;
}

.click-img {
    display: flex;
    height: 30px;
    gap: 6px;
    z-index: 1;
}

.clickSymb {
    font-weight: 300;
    font-size: 14px;
}

.click-img img {
    width: 15px;
    transform: scaleY(-1) rotate(90deg);
}

.projects-heading {
    color: white;
    font-size: 40px;
    margin: 0;
    text-shadow: -4px 3px 0px #282a2d;
}

.proj-table {
    margin-top: 15px;
    padding: 25px;
    background-color: rgba(29,29,29,0.8);
    border-radius: 25px;
    width: 400px;
}

.project-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    /* margin: 0 35px; */
}

.project-container .proj-card-container {
    /* box-shadow: -7px 7px 0px #9247d6; */
    width: 800px;
}

.proj-init-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 10px 0px;
}

.proj-init-menu button {
    font-size: 18px;
}

.projects-header-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 42.5px;
}

.projects-header {
    display: flex;
    gap: 5px;
    flex-direction: column;
    padding: 20px;
    background-color: rgba(29,29,29,0.8);
    border-radius: 25px;
    border-style: solid;
    border-width: 3px;
}

.invisible {
    visibility: hidden;
}

#projects .proj-links a:hover {
    /* background-color: #9247d6; */
    color: white;
}

.proj-table-legend {
    display: flex;
    justify-content: space-evenly;
    column-gap: 10px;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 660;
}

@media (max-width: 1300px) {
    .project-container {
        flex-direction: column;
        gap: 0;
        align-items: center;
    }

    .project-container .proj-card-container {
        /* box-shadow: -7px 7px 0px #9247d6; */
        width: auto;
    }

    .proj-table {
        width: auto;
        max-width: 500px;
    }
}
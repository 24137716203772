#landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: rgba(40,42,45,1); */
    margin-top: 110px;
    background-repeat: no-repeat;
    background-size: cover;
}

#landing h6 {
    margin: 0;
}

#landing  h1 {
    margin: 0 0 3px 0;
    font-size: 35px;
}

#landing .tags p {
    font-size: 17px;
    font-weight: 450;
}

#landing h2, h6, p {
    font-size: 17px;
}

.landing-body {
    margin: 15px 0;
}

#landing .wrapper {
    padding: 60px 0px;
    margin: 0 auto;
    width: 90%;
}

.primary-text {
    color: white
}

.landing-content {
    max-width: 1000px;
    display: flex;
    align-items: center;
    gap: 40px;
}

#profile {
    width: 300px;
    height: 300px;
    border-radius: 25px;
}

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.alt-text {
    /* color: #b581e3; */
    font-weight: 700;
}

.tags {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
}

.shadow-bg {
    background-color: rgba(29,29,29,0.8);
    border-radius: 25px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
    padding: 35px;
    position: relative;
    z-index: 1;
    background: inherit;
    overflow: hidden;
}
  
.shadow-bg:before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(90px);
}

@media (max-width: 900px) {
    #profile {
        display: none;
    }
}
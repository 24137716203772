section {
    scroll-margin-top: 110px;
    min-height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* background-repeat: no-repeat;
    background-size: cover; */
}

html {
	scroll-behavior: smooth;
}

button {
    border: 0;
}

@media (max-width: 1300px) {
    section {
        height: auto;
    }
}   
.regular-horizontal {
    display: flex;
    flex-direction: row;
}

.reverse-horizontal {
    display: flex;
    flex-direction: row-reverse;
}

.invisible {
    display: none;
}

.proj-card-container {
    margin-top: 15px;
    gap: 10px;
    color: white;
    padding: 25px;
    /* background-color: rgba(29,29,29,0.8);
    border-radius: 25px;  */
}

.project-tech {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
}

.project-tech p {
    font-size: 18px;
}

.proj-links {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.proj-links a {
    text-decoration: none;
    padding: 5px;
    font-weight: 700;
    
}

.proj-links a:hover {
    border-radius: 5px;
    color: white;
}


.proj-chem-initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.proj-text .alt-text {
    font-size: 14px;
    margin: 15px 0px 5px 0px;
}

.proj-text h2, p, h1 {
    margin: 0;
}

.proj-text h1 {
    font-size: 20px;
    font-weight: 500;
}

.featured-container .project-card-container {
    width: 500px;
}

@media (max-width: 1100px) {
    .featured-container .project-card-container {
        width: 100%;
    }
}
.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 600px;
    border-radius: 0 0 20px 20px;
}

.brand {
    display: inline;
}

.brand:hover {
    cursor: pointer;
}

.brand div {
    font-size: 60px;
    overflow:hidden;
    display: inline-block;
    transition: width 0.3s ease-in-out;
}

.brand div:nth-child(2) {
    position: absolute;
    background-color: #1a1a1a;
    width: 180px;
    z-index: 1;
}
.brand div:nth-child(4) {
    position: relative;
    width: 350px; /* Set an explicit width for animation */
    /* transition: width 0.3s ease-in-out; */
}

.brand div:nth-child(3) {
    position: relative;
}

.brand div.hide {
    width: 0;
}



.logo {
    width: 100px;
}

.nav-bar a:hover {
    color: #b581e3;
}

.nav-bar a {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin: 20px 0;
    text-decoration: none;
}

.nav-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #1a1a1a;
    position: fixed;
    z-index: 2;
    width: 100vw;
    top: 0;
}

.nav-wrapper-left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-wrapper-left-wrap {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.nav-toggle {
    width: 60px;
    height: 60px;
    align-self: center;
    margin-right: 20px;
    display: none;
    background-color: #1a1a1a;
}

.nav-toggle img {
    width: 60px;
}

@media (min-width: 750px) {
    .nav-wrapper {
        height: 110px;
    }
}

@media (max-width: 750px) {
    .nav-toggle {
        display: initial;
    }

    .nav-wrapper {
        flex-direction: column;
        background-color: transparent;
    }

    .nav-wrapper-left {
        display: flex;
        background-color: #1a1a1a;
        height: 110px;
    }

    .nav-bar {
        flex-direction: column;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.3s ease-in-out;
        align-items: flex-start;
        width: 100vw;
        border-radius: 0 0 30px 30px;
        background-color: #1a1a1a;
    }
    
    .animated {
        max-height: 400px;
    }

    .nav-bar a {
        margin-left: 35px;
    }
}
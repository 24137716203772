.featured-wrapper {
    width: 90%;
    margin: 57.5px auto 57.5px auto;
}

#featured .proj-links a {
    color: #9d9d49;
    
}

#featured .proj-links a:hover {
    background-color: #9d9d49;
    color: white;
}

#featured .alt-text {
    color: #9d9d49;
}

#featured .tag-card {
    border: #9d9d49 1px solid;
}

#featured .proj-chem-initials {
    border: #b1b14d 3px solid;
}

.featured-heading {
    color: white;
    font-size: 40px;
    margin: 0;
    padding: 15px 20px;
    border: #ede90f 3px solid;
    border-radius: 25px;
    text-shadow: -4px 3px 0px #1d1d1d;
    text-align: center;
    background-color: rgba(29,29,29,0.8);
}

.featured-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 50px;
    justify-content: center;
    margin-bottom: 20px;
}

.featured-header {
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 42.5px;
}

.invisible {
    visibility: hidden;
}

.featured-container .proj-card-container {
    width: 500px;
    /* box-shadow: 0 0 20px #9d9d49; */
}

@media (max-width: 1300px) {
    .featured-container {
        flex-direction: column;
    }

    .featured-container .proj-card-container {
        width: auto;
    }
}
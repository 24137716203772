.tag-card {
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    width: fit-content;
}

.tag-card-tag {
    margin: 0;
    padding: 8px;
}
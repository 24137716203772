.timeline-wrapper {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 55px;
}

.timeline-header {
    color: white;
    font-size: 40px;
    margin: 0 0 40px 0;
    padding: 20px;
    border: #46b358 3px solid;
    border-radius: 25px;
    background-color: #1d1d1d;
    text-shadow: -4px 3px 0px #282a2d;
}

.experience-wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 20px;
    width: 100%;
}

.experience {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    color: white;
    scroll-snap-align: start;
}

.bubble {
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    background-color: white;
    z-index: 1;
    /* box-shadow: 0 0 10px #46b358; */
}

.timeline-content {
    display: flex;
}

.progressbar {
    background: white;
    box-shadow: 0 0 10px #46b358;
    border: #46b358 2px solid;
    width: 5px;
    height: auto;
    position: relative;
    /* top: 20px; */
    left: 142px;
}

.experience-left {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 15%;
    min-width: 150px;
}

.experience-right {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    width: 85%;
    max-width: 800px;
}

.experience-right h2 {
    margin-top: 0;
    margin-bottom: 5px;
}

.experience-right img {
    width: 100px;
    height: 100px;
}

.experience-right-right {
    width: 100%;
}

.experience-right-right ul {
    padding-left: 0;
    margin: 0;
}

.experience-loc {
    margin-bottom: 20px;
}

.experience-loc, .experience-time {
    color:#46b358;
}

.experience-time {
    min-width: 100px;
}

@media (max-width: 750px) {
    .experience-right {
        width: auto;
    }

    .experience-right img {
        display: none;
    }

    .experience {
        gap: 5px;
    }
}
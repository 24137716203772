.chem-btn {
    background-color: transparent;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 8px;
}

.chem-btn:hover {
    color: white;
}

.proj-chem-initials {
    border-style: solid;
    border-width: 3px;
}
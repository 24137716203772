/* ==== these colour styles standardize the theme of the site ===*/

/* Must fill out all these classes PER color */
/* lol there's gotta be a better way of doing this */

/* PURPLE */
.purple {
    border-color: #b581e3;
}

a.purple {
    color: #b581e3;
}

.purple:hover.clickable, a.purple:hover {
    background-color: #b581e3;
}

.purple.active {
    background-color: #b581e3;
}

.purple.alt-text {
    color: #b581e3;
    background-color: transparent;
    border: none;
}

.purple.proj-table {
    box-shadow: 0 0 45px #b581e3;
}

/* RED */
.red {
    border-color: #f94449;
}

a.red {
    color: #f94449;
}

.red:hover.clickable, a.red:hover {
    background-color: #f94449;
}

.red.active {
    background-color: #f94449;
}

.red.alt-text {
    color: #f94449;
    background-color: transparent;
    border: none;
}

.red.proj-table {
    box-shadow: 0 0 45px #f94449;
}

/* GREEN */
.green {
    border-color: #46b358;
}

a.green {
    color: #46b358;
}

.green:hover.clickable, a.green:hover {
    background-color: #46b358;
}

.green.active {
    background-color: #46b358;
}

.green.alt-text {
    color: #46b358;
    background-color: transparent;
    border: none;
}

.green.proj-table {
    box-shadow: 0 0 45px #46b358;
}

/* BLUE */
.blue {
    border-color: #6ea9f7;
}

a.blue {
    color: #6ea9f7;
}

.blue:hover.clickable, a.blue:hover {
    background-color: #6ea9f7;
}

.blue.active {
    background-color: #6ea9f7;
}

.blue.alt-text {
    color: #6ea9f7;
    background-color: transparent;
    border: none;
}

.blue.proj-table {
    box-shadow: 0 0 45px #6ea9f7;
}

/* ORANGE */

.orange {
    border-color: orange;
}

a.orange {
    color: orange;
}

.orange:hover.clickable, a.orange:hover {
    background-color: orange;
}

.orange.active {
    background-color: orange;
}

.orange.alt-text {
    color: orange;
    background-color: transparent;
    border: none;
}

.orange.proj-table {
    box-shadow: 0 0 45px orange;
}

/* this is the only minimal since it is used for static non-clickables */

.grey {
    border-color: #d9d9d9;
}

.grey:hover.clickable, a.grey:hover {
    background-color: #d9d9d9;
}

a.grey {
    color: #d9d9d9;
}
.bg {
    min-height: 100%;
    background-repeat: no-repeat;
    background-attachment: local;
    background-position: top;
    background-size: cover;
    background-color: black;
}

html,
body {
  position: fixed;
  overflow: hidden;
}

.scroller {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}
.contact-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.contact-wrapper img {
    width: 40px;
}

.contact-wrapper a, .contact-wrapper button {
    transition: .2s ease-in-out 0s;
    background: none;
}

.contact-wrapper a:hover, .contact-wrapper button:hover {
    transform: scale(1.25);
}